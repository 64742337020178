/*!
 * MRL Ventures Fund
 * 
 * 
 */

/*-----------------------------------------------------------------------------------
  = Table of Contents

  1. = Global Styles
  2. = Header
  3. = Hero Space
  4. = About Us
  5. = By The Numbers section
  6. = Invesment Focus
  7. = Logos Row 
  8. =  Employee Row
  9. = Contact Row
  10. = Modal
  11. = Footer


/*-----------------------------------------------------------------------------------*/
/* 1. = Global Styles
/*-----------------------------------------------------------------------------------*/

@import url('http://fast.fonts.net/t/1.css?apiType=css&projectid=35024326-4878-4b91-bc05-90e61b3baf52');

@font-face {
  font-family: 'DIN W01 Black Italic';
  src: url('../assets/Fonts/5425970/973d086f-296a-4d74-8ac2-053d046e657e.eot?#iefix');
  src: url('../assets/Fonts/5425970/973d086f-296a-4d74-8ac2-053d046e657e.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5425970/c27280d9-cd6e-4ac9-915e-3dbcc8c280aa.woff2')
      format('woff2'),
    url('../assets/Fonts/5425970/1650453a-faf9-4b41-88d0-0b924f8ec3e2.woff')
      format('woff'),
    url('../assets/Fonts/5425970/62267c41-f9cf-4754-b558-d204f5411673.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Black';
  src: url('../assets/Fonts/5425993/1649cdb9-9d1d-45c1-a4f4-f89ffd7f602f.eot?#iefix');
  src: url('../assets/Fonts/5425993/1649cdb9-9d1d-45c1-a4f4-f89ffd7f602f.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5425993/69a6a09d-674d-41dd-9a87-4137b47bc191.woff2')
      format('woff2'),
    url('../assets/Fonts/5425993/82524774-35cd-4dd0-b03e-643e83eec686.woff')
      format('woff'),
    url('../assets/Fonts/5425993/164b143e-b89d-4f66-86a0-705070a4cb17.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Bold';
  src: url('../assets/Fonts/5426014/a94d5d16-cbe3-439e-b1a5-fbdfb180d789.eot?#iefix');
  src: url('../assets/Fonts/5426014/a94d5d16-cbe3-439e-b1a5-fbdfb180d789.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426014/8beea44e-c2c9-4889-9123-13d0e8b8d1bc.woff2')
      format('woff2'),
    url('../assets/Fonts/5426014/871e70ec-2f76-44c5-a2f1-77ee8cd0a5fe.woff')
      format('woff'),
    url('../assets/Fonts/5426014/8fa83ec7-0734-49e5-b5ad-b13a41463328.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Bold Italic';
  src: url('../assets/Fonts/5426025/31393c1c-3e18-4011-a98d-252b48422e6f.eot?#iefix');
  src: url('../assets/Fonts/5426025/31393c1c-3e18-4011-a98d-252b48422e6f.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426025/4bfc9802-18e0-45f1-b221-721200906da1.woff2')
      format('woff2'),
    url('../assets/Fonts/5426025/abff5174-3c28-4767-b3ba-8ec56b67064e.woff')
      format('woff'),
    url('../assets/Fonts/5426025/f0b0175d-906e-433e-ab60-e60c0d0779f4.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Medium Italic';
  src: url('../assets/Fonts/5426038/74a919d5-7a40-4a51-982e-bae87c827481.eot?#iefix');
  src: url('../assets/Fonts/5426038/74a919d5-7a40-4a51-982e-bae87c827481.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426038/3fd43eae-744c-4368-b386-94088f2e669b.woff2')
      format('woff2'),
    url('../assets/Fonts/5426038/07222620-b566-4e53-8361-960b63754371.woff')
      format('woff'),
    url('../assets/Fonts/5426038/091b2fc7-228b-4f55-9b43-78bfe1c8fde4.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Italic';
  src: url('../assets/Fonts/5426053/e72945c4-5029-4c68-859b-74bc6429ccd6.eot?#iefix');
  src: url('../assets/Fonts/5426053/e72945c4-5029-4c68-859b-74bc6429ccd6.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426053/59fe4d12-8c74-457e-aa68-b9ad885f8f8f.woff2')
      format('woff2'),
    url('../assets/Fonts/5426053/39432a06-ab95-4bf7-bbb6-cb84e51170e8.woff')
      format('woff'),
    url('../assets/Fonts/5426053/e93afcb1-b4f4-4177-94ab-97c66a9bb9ee.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Medium';
  src: url('../assets/Fonts/5426076/9f48a411-8295-4ad8-b97a-f4cc38cef43d.eot?#iefix');
  src: url('../assets/Fonts/5426076/9f48a411-8295-4ad8-b97a-f4cc38cef43d.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426076/79949f65-d692-489f-bd6b-b62647297229.woff2')
      format('woff2'),
    url('../assets/Fonts/5426076/b9597dec-1d68-488e-87e0-d7824cf36028.woff')
      format('woff'),
    url('../assets/Fonts/5426076/964c9b01-5d07-4ef1-8b71-3b173b54fbcc.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Regular';
  src: url('../assets/Fonts/5426089/a397c752-ee83-47cf-b8a8-b476511dd2c9.eot?#iefix');
  src: url('../assets/Fonts/5426089/a397c752-ee83-47cf-b8a8-b476511dd2c9.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426089/53fb9c60-2054-4c31-9283-5562244b4935.woff2')
      format('woff2'),
    url('../assets/Fonts/5426089/45831ca8-93bd-4808-aa5f-3c915944f727.woff')
      format('woff'),
    url('../assets/Fonts/5426089/429a3e29-88da-4603-94ff-c1450c089c88.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Light Italic';
  src: url('../assets/Fonts/5426100/60d165c5-73cd-45a1-8f89-28a2fd432339.eot?#iefix');
  src: url('../assets/Fonts/5426100/60d165c5-73cd-45a1-8f89-28a2fd432339.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426100/732e3dc3-cffa-49de-9b4b-4cb9ae593525.woff2')
      format('woff2'),
    url('../assets/Fonts/5426100/21d2896c-7c1f-4093-bb1f-e7975180b9b8.woff')
      format('woff'),
    url('../assets/Fonts/5426100/aa0de6b1-5147-44f5-bc5f-666419393cb5.ttf')
      format('truetype');
}
@font-face {
  font-family: 'DIN W01 Light';
  src: url('../assets/Fonts/5426105/f2fdd2b7-b1c3-436b-9041-724c6a02057f.eot?#iefix');
  src: url('../assets/Fonts/5426105/f2fdd2b7-b1c3-436b-9041-724c6a02057f.eot?#iefix')
      format('eot'),
    url('../assets/Fonts/5426105/a5a426db-6dbd-4135-b607-371a197c38fa.woff2')
      format('woff2'),
    url('../assets/Fonts/5426105/d6062c05-ec0a-4cd8-b859-20c330d2785f.woff')
      format('woff'),
    url('../assets/Fonts/5426105/0a2435f1-0a73-40d5-a303-fc076009ab66.ttf')
      format('truetype');
}

/* INVENTION FONTS */
@font-face {
  font-family: 'Invention Black Italic';
  src: url('../assets/Fonts/invention/EOT/Invention_W_XBdIt.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_XBdIt.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_XBdIt.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_XBdIt.woff') format('woff');
}
@font-face {
  font-family: 'Invention Black';
  src: url('../assets/Fonts/invention/EOT/Invention_W_XBd.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_XBd.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_XBd.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_XBd.woff') format('woff');
}
@font-face {
  font-family: 'Invention Bold';
  src: url('../assets/Fonts/invention/EOT/Invention_W_Bd.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_Bd.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_Bd.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_Bd.woff') format('woff');
}
@font-face {
  font-family: 'Invention Bold Italic';
  src: url('../assets/Fonts/invention/EOT/Invention_W_BdIt.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_BdIt.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_BdIt.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_BdIt.woff') format('woff');
}
@font-face {
  font-family: 'Invention Italic';
  src: url('../assets/Fonts/invention/EOT/Invention_W_It.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_It.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_It.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_It.woff') format('woff');
}
@font-face {
  font-family: 'Invention Regular';
  src: url('../assets/Fonts/invention/EOT/Invention_W_Rg.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_Rg.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_Rg.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_Rg.woff') format('woff');
}
@font-face {
  font-family: 'Invention Light Italic';
  src: url('../assets/Fonts/invention/EOT/Invention_W_LtIt.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_LtIt.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_LtIt.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_LtIt.woff') format('woff');
}
@font-face {
  font-family: 'Invention Light';
  src: url('../assets/Fonts/invention/EOT/Invention_W_Lt.eot');
  src: url('../assets/Fonts/invention/EOT/Invention_W_Lt.eot') format('eot'),
    url('../assets/Fonts/invention/WOFF2/Invention_W_Lt.woff2') format('woff2'),
    url('../assets/Fonts/invention/WOFF/Invention_W_Lt.woff') format('woff');
}

header,
header .header-content {
  position: relative;
  width: 100%;
  text-align: center;
}

body,
html {
  height: 100%;
  width: 100%;
}

body {
  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
  webkit-tap-highlight-color: #222;
}

.btn,
.navbar-default,
.navbar-default .navbar-header .navbar-brand,
h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
}
hr {
  border-color: #65bda4;
  border-width: 3px;
  max-width: 50px;
}
hr.light {
  /*  border-color: #6ecdb2;*/
  border-color: #c0d739;
}

hr.white {
  border-color: #ffffff;
}

hr.orange {
  border-color: #f7902f;
}

hr.teal {
  border-color: #00857c;
}

a {
  transition: all 0.35s !important;
  color: #13a29c !important;
  text-decoration: none !important;
}
a:focus,
a:hover {
  color: #13a29c;
}
p {
  font-size: 20px;
  line-height: 1.5;
}

.pt-25 {
  padding-top: 25px;
}

.section-heading {
  margin-top: 0;
}

.section-heading {
  color: #333e48;
  font-weight: 600;
  font-size: 27px;
}

.text-teal {
  color: #00837b;
}

.text-white {
  color: #ffffff;
}

.text-blue {
  color: #333e48;
  margin-top: 25px;
}

.bg-offwhite {
  background-color: #ece6e6 !important;
  color: #333e48;
}

section {
  padding: 60px 0;
}

.no-padding {
  padding: 0;
}
.navbar-default {
  /*  background-color: #fff;*/
  background-color: #0c233f;
  border-color: rgba(34, 34, 34, 0.05);
  transition: all 0.35s;
}
.btn-default,
.portfolio-box .portfolio-box-caption {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
}
.navbar-brand {
  font-size: 25px;
}

.navbar-default .navbar-header .navbar-brand {
  color: #009894;
  font-weight: 700;
  text-transform: none;
}
.navbar-default .navbar-header .navbar-brand:focus,
.navbar-default .navbar-header .navbar-brand:hover {
  color: #eb3812;
}
.navbar-default .navbar-header .navbar-toggle {
  font-weight: 700;
  font-size: 12px;
  color: #666666;
  text-transform: uppercase;
}
.navbar-default .nav > li > a,
.navbar-default .nav > li > a:focus {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}
.navbar-default .nav > li > a:focus:hover,
.navbar-default .nav > li > a:hover {
  color: #c0d739;
}
.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: #c0d739 !important;
  background-color: transparent;
}
.navbar-default .nav > li.active > a:focus:hover,
.navbar-default .nav > li.active > a:hover {
  background-color: transparent;
}

@media (min-width: 768px) {
  .navbar-default {
    background-color: #0c233f !important;
    border-color: rgba(12, 35, 63, 0.3);
  }
  .navbar-default .navbar-header .navbar-brand {
    color: #009894;
  }
  .navbar-default .navbar-header .navbar-brand:focus,
  .navbar-default .navbar-header .navbar-brand:hover {
    color: #ffffff;
  }

  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: #ffffff;
    font-weight: 300 !important;
  }
  .navbar-default .nav > li > a:focus:hover,
  .navbar-default .nav > li > a:hover {
    color: #c0d739;
  }
  .navbar {
    height: 100px;
  }
}

@media (max-width: 768px) {
  header,
  header .header-content {
    margin-top: 100px;
  }
}

.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.btn-default {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
  transition: all 0.35s;
  text-transform: uppercase;
  font-size: 16px;
  border-radius: 0px;
  padding: 10px 20px;
}
.btn-default.active,
.btn-default.focus,
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.open > .dropdown-toggle.btn-default {
  color: #fff;
  background-color: #0c233f;
  border-color: #ffffff;
}

/*-----------------------------------------------------------------------------------*/
/* 2. = Header
/*-----------------------------------------------------------------------------------*/
.navbar-default {
  box-shadow: 9px 9px 9px 0 rgba(47, 44, 44, 0.2);
}

/*
.navbar {
    max-height: 100px;
}	
*/

.navbar-default .navbar-collapse {
  border-color: #fff;
}

.main-nav {
  margin-top: 25px;
  margin-bottom: 25px;
}

.navbar-nav {
  float: none;
  text-align: center;
}

.navbar-nav > li {
  display: inline-block;
  float: none;
}

.headright img {
  margin-top: 5px;
  float: left;
  height: 30px;
}

.headright div {
  /*float:left;*/
  /*float:right;*/
  height: 100%;
  margin-top: 25px;
  /*margin-top: -32px;*/
}

.headright p {
  float: left;
  font-size: 13px;
  margin-bottom: 0px;
  color: #888888;
}

.headright a {
  color: #888888;
}

.headright a:hover {
  color: #666666;
}
.headright a:selected {
  color: #666666;
}

@media (max-width: 991px) {
  .main-nav ul {
    margin-top: 0px;
  }

  .headright div {
    margin-top: 0px;
  }
  .navbar {
    min-height: 100px;
  }
}

@media (max-width: 768px) {
  .navbar-right {
    float: left !important;
  }

  .navbar-collapse {
    float: left !important;
  }

  .headright {
    float: left;
    clear: both;
  }

  /*	.navbar {
    max-height: 50px;
}	*/
}

@media (max-width: 767px) {
  .main-nav {
    margin-top: 40px;
  }

  .navbar-default .navbar-header .navbar-toggle {
    margin-top: 25px;
  }

  .navbar-nav {
    float: none;
    text-align: left;
  }

  .navbar-nav > li {
    display: inherit;
  }
}

@media (max-width: 1200px) {
  .navbar-default .nav > li > a {
    padding-left: 5px;
    padding-right: 5px;
  }

  .navbar-brand {
    font-size: 20px;
  }
}

@media (max-width: 544px) {
  .navbar-default .navbar-header .navbar-toggle {
    float: left;
  }
}

/*-----------------------------------------------------------------------------------*/
/* 3. = Hero Space
/*-----------------------------------------------------------------------------------*/
header {
  background-color: #fff;
  color: #fff;
  background-image: url('../assets/images/bg_hero.jpg');
  -webkit-background-size: auto, cover;
  -moz-background-size: auto, cover;
  background-size: auto, cover;
  -o-background-size: auto, cover;
  background-position: center;
}

header .header-content {
  padding: 100px 15px;
}
header .header-content .header-content-inner h1 {
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 30px;
}

header .header-content .header-content-inner hr {
  margin: 30px auto;
}
header .header-content .header-content-inner p {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  header {
    height: 750px;
  }
  header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
  header .header-content .header-content-inner {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  header .header-content .header-content-inner h1 {
    font-size: 50px;
  }
  header .header-content .header-content-inner p {
    font-size: 18px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

#homeHeading {
  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 2px;
}

.hometext {
  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 26px !important;
  font-weight: 400 !important;
}

.hometextsm {
  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
  font-size: 20px !important;
  font-weight: 300 !important;
}

#page-scroll img {
  width: 55px;
}

/*-----------------------------------------------------------------------------------*/
/* 4. = About us
/*-----------------------------------------------------------------------------------*/

.threetypes p {
  font-size: 20px !important;
}

.threetypes .typeone {
  padding: 3px 14px;
  height: 150px;
  border-bottom: 5px solid #c0d739;
  text-align: center;
  background: #fff;
}

.threetypes .typetwo {
  padding: 14px;
  height: 150px;
  border-bottom: 5px solid #00837b;
  text-align: center;
  background: #fff;
}

.threetypes .typethree {
  padding: 14px;
  height: 150px;
  border-bottom: 5px solid #6ecdb2;
  text-align: center;
  background: #fff;
}

.typethree span {
  font-weight: bold;
}

@media (max-width: 1200px) {
  .threetypes .typeone {
    height: auto !important;
    margin-bottom: 15px;
    min-height: 0px;
  }

  .threetypes .typetwo {
    height: auto !important;
    margin-bottom: 15px;
    min-height: 0px;
  }

  .threetypes .typethree {
    height: auto !important;
    margin-bottom: 15px;
    min-height: 0px;
  }
}

/*-----------------------------------------------------------------------------------*/
/* 5. = BY THE NUMBERS
/*-----------------------------------------------------------------------------------*/
.boldstat {

  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
  letter-spacing: 2px;
  font-size: 25px;
  text-transform: uppercase;
  color: #005c5d;
}

.scope {
  background: #00837b;
  padding: 10px;
  color: #fff;
  border-bottom: 5px solid #005c5d;
}

.scope p {
  text-transform: uppercase;
  line-height: 20px;
}

.scope .sm-text {
  font-size: 16px;
  text-transform: lowercase;
}

.media-body {
  display: table-cell;
  vertical-align: middle;
}

.outer {
  margin-left: auto;
  margin-right: auto;
  width: 85%;
}

/*-----------------------------------------------------------------------------------*/
/* 6. = Invesment Focus
/*-----------------------------------------------------------------------------------*/
#investment {
  min-height: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: inherit;
  background-image: url('../assets/images/bg_delivering.jpg');
  color: #243444;
}

#investment h2 {
  color: #fff;
}

#investment p {
  color: #243444;
  font-size: 18px;
}

#boxes .box h2 {
  color: #00837b;
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 700;
}

.box {
  padding: 20px;
  height: auto;
  min-height: 331px;
  border-bottom: 5px solid #c0d739;
  text-align: center;
  background: #fff;
}

/*-----------------------------------------------------------------------------------*/
/* 7. = Logos Row
/*-----------------------------------------------------------------------------------*/

.portfolio-section .img-responsive {
  border: 1px solid #e9e9e9;
  padding: 15px;
}

.portfolio-box {
  position: relative;
  display: block;
  max-width: 650px;
  margin: 0 auto;
}
.portfolio-box .portfolio-box-caption {
  color: #fff;
  opacity: 0;
  display: block;
  background: rgba(0, 92, 93, 0.9);
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  transition: all 0.35s;
}

.portfolio-box .portfolio-box-caption .portfolio-box-caption-content {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-category,
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-name {
  /* font-family: 'DIN W01 Regular', 'Arial Narrow', Arial, sans-serif; */
  font-family: 'Invention Regular', 'Arial Narrow', Arial, sans-serif;
  padding: 0 15px;
}
.portfolio-box
  .portfolio-box-caption
  .portfolio-box-caption-content
  .project-category {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

.portfolio-box:hover .portfolio-box-caption {
  opacity: 1;
}
.portfolio-box:focus {
  outline: 0;
}

@media (max-width: 1200px) {
  #portfolio .img-responsive {
    border: none;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .portfolio-box
    .portfolio-box-caption
    .portfolio-box-caption-content
    .project-category {
    font-size: 16px;
  }
  .portfolio-box
    .portfolio-box-caption
    .portfolio-box-caption-content
    .project-name {
    font-size: 14px;
  }
}

/*-----------------------------------------------------------------------------------*/
/* 8. = Employee Row 
/*-----------------------------------------------------------------------------------*/

#ppl {
  padding: 40px 0;
  background: #0b7a7c;
  background-image: url('../assets/images/bg_people.jpg');
  /* background-repeat: no-repeat; */
  /* background-position: center left; */
  position: relative;
}

#ppl h2 {
  color: #ffffff;
}

#ppl img {
  width: 100%;
  padding: 10px;
  max-width: 455px;
}

#ppl p {
  line-height: 1.3em;
  font-size: 20px;
  color: #ffffff;
}

#ppl span {
  font-size: 15px;
}

/*-----------------------------------------------------------------------------------*/
/* 9. = Contact Row
/*-----------------------------------------------------------------------------------*/

/*-----------------------------------------------------------------------------------*/
/* 10. = Modal
/*-----------------------------------------------------------------------------------*/
.close {
  font-size: 45px;
}

.modal-backdrop.in {
  opacity: 0.8;
}

div.image-div img {
  float: left;
  margin-right: 20px;
}

.sideText {
  overflow: hidden;
}

.sideText p {
  font-size: 16px;
}

.sideText span {
  font-size: 18px;
}

@media only screen and (max-width: 480px) {
  div.image-div img {
    float: none;
  }
}

/*-----------------------------------------------------------------------------------*/
/* 10. = Footer
/*-----------------------------------------------------------------------------------*/

footer p {
  font-size: 15px;
  float: left;
}

footer a {
  font-size: 15px;
  margin-left: 12px;
  color: #009894;
}

footer a:hover {
  color: #009894;
}
footer a:active {
  color: #009894;
}
footer a:visited {
  color: #009894;
}

footer .row {
  border-top: 1px solid #999999;
  padding-top: 10px;
}
