.container-fluid {
  padding: 0 !important;
}

.hr-divider {
  width: 60px;
  height: 3px;
  border: 1px solid;
  position: relative;
  margin: 20px auto 15px auto;
}

.hr-divider.light {
  border-color: #c0d739;
  background-color: #c0d739;
}

.hr-divider.teal {
  border-color: #00857c;
  background-color: #00857c;
}

a.nav-link {
  color: #fff;
}

.img-responsive {
  width: 100%;
}

.portfolio-item {
  margin-bottom: 20px;
}

.btn-white-border {
  color: white !important;
  border-color: white !important;
  transition-duration: 0.3s;
}

.btn-white-border:hover {
  background: white;
  color: black !important;
}

.left-text {
  text-align: left !important;
}

.modal-dialog {
  width: 700px !important;
}

.modal-content {
  width: 700px !important;
}

.modal-btn {
  width: 40px !important;
  position: absolute !important;
  right: 0 !important;
  background: transparent !important;
  color: gray !important;
  border: none !important;
  font-weight: bold !important;
}

.scroll-offset {
  position: relative;
  display: block;
  top: -100px;
  visibility: hidden;
}

.nav-link:hover {
  color: #c0d739 !important;
}

.green-link {
  color: #c0d739 !important;
}

.no-hover {
  display: none !important;
}

.float-right {
  float: right;
}

@media only screen and (max-width: 1200px) {
  .img-responsive {
    width: 50%;
  }

  .footer-links {
    text-align: center !important;
  }
}

@media only screen and (max-width: 768px) {
  .img-responsive {
    width: 80% !important;
  }

  .modal-dialog {
    width: 100% !important;
  }

  .modal-content {
    width: 100% !important;
  }

  .modal-dialog {
    max-width: 100% !important;
    margin: 0 !important;
  }

  .modal {
    padding: 0 !important;
  }

  #box-2 {
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px !important;
  }
}

@media (max-width: 440px) {
  .navbar-brand {
    width: 75% !important;
  }
  .navbar-brand img {
    width: 100% !important;
  }
}
